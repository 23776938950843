<template>
  <div class="container">
    <div class="d-flex w-100">
      <div id="pipeline">
        <h4 class="fw-light mt-3">Оптимизация документооборота при поверке и замене счетчиков воды</h4>
        <h5 class="fw-light mt-3">Для управляющих компаний (УК) и поверочных лабораторий (ПЛ)</h5>

        <div class="card p-0 shadow-sm mt-5">
          <div class="card-body d-flex flex-nowrap p-0 align-items-center">
            <div class="p-4 actor text-nowrap">
              <img src="/img/ld-c-1.svg" alt="">
              <span class="fw-bold ms-3">Собственник</span>
            </div>
            <div class="vr mx-3"></div>
            <div class="ms-2">
              Оставляет заявку в ПЛ на поверку/замену счетчика
            </div>
          </div>
        </div>

        <img src="/img/ld-arrow.svg" class="arrow">

        <div class="card p-0 shadow-sm">
          <div class="card-body d-flex flex-nowrap p-0 align-items-center">
            <div class="p-4 actor text-nowrap">
              <img src="/img/ld-c-3.svg" alt="">
              <span class="fw-bold ms-3">Сотрудник ПЛ</span>
            </div>
            <div class="vr mx-3"></div>
            <div class="ms-2">
              Заполняет заявку, назначает исполнителя. Она становится видна поверителю в приложении на смартфоне
            </div>
          </div>
        </div>

        <img src="/img/ld-arrow.svg" class="arrow">

        <div class="card p-0 shadow-sm">
          <div class="card-body d-flex flex-nowrap p-0 align-items-center">
            <div class="px-4 py-2 actor text-nowrap d-flex">
              <img src="/img/ld-c-2.svg" alt="">
              <div>
                <div class="fw-bold ms-3">
                  Поверитель
                </div>
                <div>
                  <a
                    href='https://play.google.com/store/apps/details?id=ru.pv_1.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                    target="_blank">
                    <img src='/img/ru_badge_web_generic.png'
                         style="width: 130px !important;"
                         @load="setBgHeight"
                         alt='Доступно в Google Play'>
                  </a>
                </div>
              </div>
            </div>
            <div class="vr mx-3"></div>
            <div class="ms-2">
              Выполняет поверку/замену, фотографирует счетчики, акт поверки/замены. Фото становятся видны сотруднику ПЛ
            </div>
          </div>
        </div>

        <img src="/img/ld-arrow.svg" class="arrow">

        <div class="card p-0 shadow-sm">
          <div class="card-body d-flex flex-nowrap p-0 align-items-center">
            <div class="p-4 actor text-nowrap">
              <img src="/img/ld-c-3.svg" alt="">
              <span class="fw-bold ms-3">Сотрудник ПЛ</span>
            </div>
            <div class="vr mx-3"></div>
            <div class="ms-2">
              По фото счетчиков и актов заполняет данные по поверке/замене счетчиков. Отправляет данные с фотографиями в
              УК
            </div>
          </div>
        </div>

        <img src="/img/ld-arrow.svg" class="arrow">

        <div class="card p-0 shadow-sm">
          <div class="card-body d-flex flex-nowrap p-0 align-items-center">
            <div class="p-4 actor text-nowrap">
              <img src="/img/ld-c-3.svg" alt="">
              <span class="fw-bold ms-3">Сотрудник УК</span>
            </div>
            <div class="vr mx-3"></div>
            <div class="ms-2 lh-lg">
              По фото счетчиков и актов проверяет данные по поверке/замене счетчиков. Формирует реестр для МФЦ
            </div>
          </div>
        </div>
      </div>
      <img src="/img/welcome-bg.svg" class="bg-img px-5 d-none">
    </div>
  </div>
  <p-footer/>
</template>

<script setup>
import PFooter from '@/components/Footer'

const setBgHeight = () => {
  setTimeout(() => {
    const pipeline = document.querySelector('#pipeline')
    document.querySelector('.bg-img').style.height = `${pipeline.offsetHeight - 100}px`
  }, 100)
}

</script>

<style scoped>
@media (min-width: 1200px) {
  .bg-img {
    display: block !important;
  }
}

.actor {
  width: 220px !important;
  min-width: 220px !important;
  max-width: 220px !important;
}

.arrow {
  margin-left: 88px;
}
</style>
